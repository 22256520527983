import React from 'react';
import styled from 'styled-components';
import GatsbyImage, {FluidObject} from 'gatsby-image';
import {documentToReactComponents} from '@contentful/rich-text-react-renderer';

const Contact: React.FC<ContactBlockFragment> = ({name, description, location}) => (
    <Container>
        <BodyContainer>

            <TextContainer>

                {documentToReactComponents(description?.json, {})}

                {
                    location &&

                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d712898.4990970055!2d-74.26787713597164!3d45.73591247910507!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4cc8dbe7217f6807%3A0x871e2d176588c68f!2s4535+B+Chemin+Martin%2C+Terrebonne%2C+QC+J6X+0B2!5e0!3m2!1sen!2sca!"
                      className="map"
                      width="100%" height="400" frameBorder="0" allowFullScreen></iframe>
                }
            </TextContainer>
        </BodyContainer>

    </Container>
);
const Container = styled.div`
  width: 100%;
  color: white;
  padding: 2em;
  
  hr {
  background-color: white;
  }
`;


const BodyContainer = styled.div`
  color: white;
  display: flex;
  flex-direction: row;  
  align-items: center;
  justify-content: center;
  color: black;
  text-align: center;
  
  @media (max-width: 800px) {
    flex-direction: column;
  }
`;


const TextContainer = styled.div`
  margin: 2vw;
  padding: 2vw;
  flex:1;
  color: white;
  a {
    color: white;
    text-decoration: none;
  }

`;


export default Contact;
