import React from 'react';
import styled from 'styled-components';
import GatsbyImage, {FluidObject} from 'gatsby-image';
import {documentToReactComponents} from '@contentful/rich-text-react-renderer';
import {ProductBlockFragment} from '../../../gatsby-graphql';
import {BaseContainer, HeroContainer} from '../../styles/BaseComponents';
import {colors, sizes} from '../../styles/theme';
//import {Slide, Fade} from "react-awesome-reveal";


const Product: React.FC<ProductBlockFragment> = ({name, image, data, body}) => (

    <Container backgroundColor={data?.color}>

        <BodyContainer backgroundColor={data?.color}>

            <TextContainer backgroundColor={data?.color}>
                <ImageContainer backgroundColor={data?.color}>
                    <GatsbyImage
                        fadeIn={true}
                        durationFadeIn={200}
                        fluid={image?.fluid as FluidObject}/>
                </ImageContainer>
                <NameContainer backgroundColor={data?.color}>
                    <h1>{name}</h1>
                </NameContainer>

                <div>
                    {documentToReactComponents(body?.json, {})}
                </div>
            </TextContainer>
        </BodyContainer>

    </Container>

);
const Container = styled.div`
  transition: all 0.5s ease;
  min-height: 800px;
  width: 45%;
  
  background-color: #DDD;
  margin: 1em auto;
  td, th {
    text-align: center
    
  }
  
  box-shadow: ${props => props.backgroundColor} 1px 1px 16px;

  :hover {
    box-shadow: ${props => props.backgroundColor} 1px 10px 16px;
  }
  
  p {
    margin: 0;
    padding-left: 10px;
    padding-right: 10px;
  }
  
  table {    
    transition: all 0.5s ease;
    margin: auto;
    border: 1px solid darkgrey;
    margin-bottom: 20px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 3px;
  }
  

  
 
  @media (max-width: 800px) {
     padding-top: 0em;
     margin-bottom: 2em;
     width: 90%;
     min-height: unset;


  }
 
  
`;

const ImageContainer = styled.div`
    //background-color: black;
    padding-top: 5px;
    //background-color: ${props => props.backgroundColor};
`


const BodyContainer = styled.div`
  
  margin: auto;
  padding: 20px;
  

  transition: all 0.5s ease;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: black;
  text-align: center;
  align-items: start;
  
  
  
  
  @media (max-width: 800px) {
    box-shadow: none
    align-items: center;
    padding: 0px;
    padding-top: 0em;
    padding-bottom: 2em;
    height: auto;

  }
  
`;


const TextContainer = styled.div`
  max-width: 500px;
  padding-left: 2vw;
  padding-right: 2vw;
  flex:1;
  @media (max-width: 800px) {
    width: 100vw;
    max-width: 500px;
    padding-bottom: 10px;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 0px;
  }
  
  .gatsby-image-wrapper {
    max-width: 350px;
    max-height: 350px;
    margin:auto;
    padding: 10px;
  }
  

`;

const NameContainer = styled.div`

  text-align: center;
  font-size: .85em;
  letter-spacing: 1em;
  text-transform: uppercase; 
  h1 {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 18px;
    background-color: ${props => props.backgroundColor};
    border: 10px solid black;
    padding: 10px;
    font-size: 1.2rem;
    margin: 0px;
  }

  
  
  
`;


export default Product;
