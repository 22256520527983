import React from 'react';
import Hero from '../Hero';
import EmbeddedRif from '../EmbeddedRif';
import Product from '../Product';
import Contact from '../Contact';
import Card from '../Card';


interface IContentBlocks {
    [key: string]: React.FC;
}

const Blocks: IContentBlocks = {
    Hero: Hero,
    EmbeddedRif: EmbeddedRif,
    Product: Product,
    Contact: Contact,
    Card: Card
};


export default Blocks;
