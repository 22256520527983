import React from 'react';
import {PageBlocksFragment} from '../../../gatsby-graphql';
import Blocks from './Blocks';
import styled from "styled-components";

interface IPageContentProps {
    blocks?: PageBlocksFragment['blocks'] | null;
}

const PageBlocks: React.FC<IPageContentProps> = ({blocks, productView}) => {

    if (productView) {

        let products;
        let productIndex = blocks?.findIndex((contentBlock) => {
            const ComponentName = contentBlock?.__typename.replace('Contentful', '');
            if (!ComponentName || !Blocks[ComponentName]) return null;
            if (ComponentName === 'Product') {
                return true
            }
        })

        if (productIndex) {
            products = blocks?.filter((contentBlock) => {
                const ComponentName = contentBlock?.__typename.replace('Contentful', '');
                if (!ComponentName || !Blocks[ComponentName]) return false;
                if (ComponentName === 'Product') {
                    return true
                }
            }) || []
        }


        let contents = blocks?.map((contentBlock, index) => {
            const ComponentName = contentBlock?.__typename.replace('Contentful', '');
            if (!ComponentName || !Blocks[ComponentName] || ComponentName === 'Product') return null;
            const Component = Blocks[ComponentName];
            return (
                <Component
                    key={`Key1-${contentBlock?.__typename}-${index}`}
                    {...contentBlock}
                />
            );
        }) || []

        let productContents = (<ProductContainer>
            {
                products?.map((contentBlock, index) => {
                    const ComponentName = contentBlock?.__typename.replace('Contentful', '');
                    if (!ComponentName || !Blocks[ComponentName]) return null;
                    const Component = Blocks[ComponentName];
                    return (
                        <Component
                            key={`Key-${contentBlock?.__typename}-${index}`}
                            {...contentBlock}
                        />
                    );
                })

            }
        </ProductContainer>)


        if (productIndex && contents?.length > 0) {
            contents.splice(productIndex, 0, productContents)
        }


        return (
            <>
                {contents}
            </>
        )
    } else {

        return (
            <>
                {blocks?.map((contentBlock, index) => {
                    const ComponentName = contentBlock?.__typename.replace('Contentful', '');
                    if (!ComponentName || !Blocks[ComponentName]) return null;
                    const Component = Blocks[ComponentName];

                    console.log( ComponentName, contentBlock)
                    return (
                        <Component
                            key={`Key2-${contentBlock?.__typename}-${index}`}
                            {...contentBlock}
                        />
                    );
                })}
            </>

        )
    }

}


export default PageBlocks;

const ProductContainer = styled.div`
  background-color: lightgrey;
  padding-top: 4em;
  padding-bottom: 4em;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  

`;
