import React from 'react';
import {documentToReactComponents} from '@contentful/rich-text-react-renderer';
import styled from 'styled-components';
import {EmbeddedRifFragment} from '../../../gatsby-graphql';
import {BaseContainer} from '../../styles/BaseComponents';
import {INLINES, BLOCKS, MARKS} from '@contentful/rich-text-types'

const richTextImages = {};
import GatsbyImage, {FixedObject, FluidObject} from 'gatsby-image';

const handleVideoMounted = element => {
    // if (element !== null) {
    //     element.currentTime = 0;
    // }
};

const dtrOptions = {
    renderNode: {
        [BLOCKS.EMBEDDED_ASSET]: (node, children) => {


            let {description, title, file} = node.data.target.fields

            if (file && file.contentType === "video/quicktime") {
                return (

                    <video controls muted={true} autoPlay={true} ref={handleVideoMounted} playsInline={true}
                           title={title}
                           alt={title}>
                        <source src={file.url} type="video/mp4"/>
                    </video>

                )
            } else if (file && file.contentType === "image/png") {
                return (

                    <img src={file.url} alt={title}/>

                )
            }
        },
        [BLOCKS.TABLE_ROW]: (node, children) => {
            if (
                children.every((node) => node.nodeType === BLOCKS.TABLE_HEADER_CELL)
            ) {
                // all children are header cells, so we should wrap the row
                // with a <thead /> tag
                return (
                    <thead>
                    <tr>{children}</tr>
                    </thead>
                );
            } else {
                // not a header row, so we can render an ordinary <tr />
                return <tr>{children}</tr>;
            }
        },
    },
};

const EmbeddedRif: React.FC<EmbeddedRifFragment> = ({body}) => {


        return <StyledBaseContainer>
            {documentToReactComponents(body?.json, dtrOptions)}
        </StyledBaseContainer>

    }
;


const StyledBaseContainer = styled(BaseContainer)`
  background: black;
  color: white;
  text-align: center;
  padding-top: 1em;
  padding-bottom: 1em;
  transition: all 0.5s ease;

  hr {
   background-color: white
  }
  
  p {
    text-align: left;
    max-width: 600px;
    margin: auto ;
    padding-top: 1em;
    font-size: 1.2em;  
  }
  
  video {
    padding:0;
    margin:0;
    width: 100%;
  }
  
   img {
    margin:0;
  }
  

    a {
      
      
      color:black;
      background:white;
      font-size: 16px;
      letter-spacing: 2px;
      text-decoration: none;
      text-transform: uppercase;
      
      cursor: pointer;
      //border: 2px solid;
      padding: 0.55em 0.75em;
      
      
      position: relative;
      user-select: none;
      
      
    }
    
    a:hover {
      color:white;
      background:grey;
    }
        
        
        
        
  

table {
  color: white;
  background-color: black;
  border: 0px solid #ccc;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
 
}

tbody {
  vertical-align: top;
}

table caption {
  font-size: 1.5em;
  margin: .5em 0 .75em;
}

table tr {
  color: white;
  background-color: black;
  border: 0px solid #ddd;
  padding: 2em;
}

table th,
table td {
  padding: 1em;
  text-align: center;
  border: none;
}

table th:first-child,
  td:first-child {
    padding-left: 20px;
  }

table th {
  text-align: center;
  font-size: .85em;
  letter-spacing: 1em;
  text-transform: uppercase;
}

@media screen and (max-width: 600px) {


   padding-top: 1em;
   padding-bottom: 1em;
   padding-left: 2em;
   padding-right: 2em;


  table {
    border: 0px solid #ddd;
  }

  table thead {
    border: 0px solid #ddd;
    display: block;
    margin-bottom: .625em;
  }
  
  table thead::before {
    /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }
  
  table tr {
    border-bottom: 0px solid #ddd;
    display: block;
    margin-bottom: .625em;
  }
  
  table td {
    border: 0px solid #ddd;
    display: block;
    font-size: .8em;
    text-align: center;
  }
  
  table td::before {
    /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }
  
  table td:last-child {
    border-bottom: 0;
  }
  

  img {
    max-height: 33vh;
    //max-width: 40vw;
  }


}

    


`;

export default EmbeddedRif;
